import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getRestaurantInfo } from 'actions/restaurantInfo.action'
import { getCategories } from 'actions/categories.action'
import { getSubCategories } from 'actions/subCategories.action'
import { getProducts } from 'actions/products.action'
import IRestaurantInfo from 'models/IRestaurantInfo'
import ICategory from 'models/ICategory'
import IProduct from 'models/IProduct'
import ISubCategory from 'models/ISubCategory'

export interface CategoryProps {}

export const useCategory = (props: CategoryProps) => {
  const { restaurantName, subCategoryId } = useParams<{
    restaurantName: string
    subCategoryId: string
  }>()

  const dispatch = useAppDispatch()

  const [subCategoryName, setSubCategoryName] = useState<string>('')
  const [currency, setCurrency] = useState<string>('')

  const restaurantInfo: IRestaurantInfo | null = useSelector(
    (state: RootState) => state.restaurantInfo.restaurantInfo
  )
  const categories: ICategory[] | null = useSelector(
    (state: RootState) => state.categories.categories
  )
  const subCategories: ISubCategory[] | null = useSelector(
    (state: RootState) => state.subCategories.subCategories
  )
  const products: IProduct[] | null = useSelector(
    (state: RootState) => state.products.products
  )

  useEffect(() => {
    if (subCategories && subCategoryId) {
      const name = subCategories.find(
        (subCategory) => subCategory.id === +subCategoryId
      )?.name

      setSubCategoryName(name || '')
    }
  }, [subCategories])

  useEffect(() => {
    try {
      if (!restaurantInfo) dispatch(getRestaurantInfo(restaurantName || ''))
      if (!categories || !categories.length)
        dispatch(getCategories(restaurantName || ''))
      if (!subCategories || !subCategories.length)
        dispatch(getSubCategories(restaurantName || ''))
      if (!products || !products.length)
        dispatch(getProducts(restaurantName || ''))
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (restaurantInfo) {
      setCurrency(restaurantInfo.currency)

      const oldStyleAttributes = document.getElementsByClassName(
        'restaurant-variables'
      )

      if (!oldStyleAttributes.length) {
        const styleTag = document.createElement('style')

        styleTag.setAttribute('class', 'restaurant-variables')
        styleTag.innerHTML = `
          :root {
            --primary-color: ${restaurantInfo.primaryColor};
            --secondary-color: ${restaurantInfo.secondaryColor};
            --tertiary-color: ${restaurantInfo.tertiaryColor};
          }
        `
        document.head.appendChild(styleTag)
      }
    }
  }, [restaurantInfo])

  return {
    ...props,
    restaurantName,
    subCategoryName,
    currency,
    products,
  }
}
