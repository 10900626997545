import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import ISubCategory from 'models/ISubCategory'

export interface SubCategoriesProps {
  activeCategory: number
}

export const useSubCategories = (props: SubCategoriesProps) => {
  const { activeCategory } = props
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([])
  const subCategoriesData: ISubCategory[] | null = useSelector(
    (state: RootState) => state.subCategories.subCategories
  )

  useEffect(() => {
    if (subCategoriesData && activeCategory) {
      const filteredSubCategories = subCategoriesData.filter(
        (subCategory) => subCategory.categoryId === +activeCategory
      )
      setSubCategories(filteredSubCategories)
    } else {
      setSubCategories([])
    }
  }, [activeCategory, subCategoriesData])

  return {
    ...props,
    subCategories,
  }
}
