import React from 'react'
import { useCategories, CategoriesProps } from './useCategories'
import Category from 'components/Category/Category'
import styles from './Categories.module.scss'

const Categories: React.FC<CategoriesProps> = (props: CategoriesProps) => {
  const { categories, activeCategory, handleCategoryClick } =
    useCategories(props)

  return (
    <div className={styles.categories}>
      {categories && categories.length &&
        categories.map((category) => (
          <Category
            key={`category-${category.id}`}
            id={category.id}
            name={category.name}
            isActive={category.id === activeCategory}
            onClick={handleCategoryClick}
          />
        ))}
    </div>
  )
}

export default Categories
