import { createSlice } from '@reduxjs/toolkit'
import ICategory from 'models/ICategory'

export interface CategoriesState {
  isLoading: boolean
  isGetCategoriesSuccess: boolean
  categories: ICategory[] | null
}

const initialState: CategoriesState = {
  isLoading: false,
  isGetCategoriesSuccess: false,
  categories: null,
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getCategories(state, action) {
      state.isLoading = false
      state.isGetCategoriesSuccess = true
      state.categories = action.payload.categories
    },
  },
})

export const { apiRequest, apiFailure, getCategories } = categoriesSlice.actions
export default categoriesSlice.reducer
