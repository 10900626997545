export interface CategoryProps {
  id: number
  name: string
  isActive: boolean
  onClick: (id: number) => void
}

export const useCategory = (props: CategoryProps) => {
  const { id, onClick } = props

  const handleCategoryClick = () => {
    onClick(id)
  }

  return {
    ...props,
    handleCategoryClick,
  }
}
