import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  sendEmail as sendEmailReducer,
} from 'reducers/comingSoon.reducer'
import { comingSoonService } from 'services/comingSoon.service'
import ISendEmail from 'models/ISendEmail'

export const sendEmail =
  (emailBody: ISendEmail) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await comingSoonService.sendEmail(emailBody)
      if (response) {
        dispatch(sendEmailReducer())
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }
