import { combineReducers } from 'redux'
import restaurantInfoReducer, {
  RestaurantInfoState,
} from './restaurantInfo.reducer'
import categoriesReducer, { CategoriesState } from './categories.reducer'
import subCategoriesReducer, {
  SubCategoriesState,
} from './subCategories.reducer'
import productsReducer, { ProductsState } from './products.reducer'
import comingSoonReducer, { ComingSoonState } from './comingSoon.reducer'

const rootReducer = combineReducers({
  restaurantInfo: restaurantInfoReducer,
  categories: categoriesReducer,
  subCategories: subCategoriesReducer,
  products: productsReducer,
  comingSoon: comingSoonReducer,
})

export type RootState = {
  restaurantInfo: RestaurantInfoState
  categories: CategoriesState
  subCategories: SubCategoriesState
  products: ProductsState
  comingSoon: ComingSoonState
}

export default rootReducer
