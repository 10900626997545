import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import IProduct from 'models/IProduct'

export interface ProductsProps {
  restaurantCurrency: string
  productsData: IProduct[] | null
}

export const useProducts = (props: ProductsProps) => {
  const { productsData } = props
  const { subCategoryId } = useParams<{ subCategoryId: string }>()
  const [products, setProducts] = useState<IProduct[]>([])

  useEffect(() => {
    if (productsData && subCategoryId) {
      const filteredProducts = productsData.filter(
        (product) => product.subCategoryId === +subCategoryId
      )
      setProducts(filteredProducts)
    } else setProducts([])
  }, [subCategoryId, productsData])

  return {
    ...props,
    products,
  }
}
