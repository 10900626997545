import { createSlice } from '@reduxjs/toolkit'
import IProduct from 'models/IProduct'

export interface ProductsState {
  isLoading: boolean
  isGetProductsSuccess: boolean
  products: IProduct[] | null
}

const initialState: ProductsState = {
  isLoading: false,
  isGetProductsSuccess: false,
  products: null,
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getProducts(state, action) {
      state.isLoading = false
      state.isGetProductsSuccess = true
      state.products = action.payload.products
    },
  },
})

export const { apiRequest, apiFailure, getProducts } = productsSlice.actions
export default productsSlice.reducer
