import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import ICategory from 'models/ICategory'
// import CategoriesData from 'data/Categories.json'

export interface CategoriesProps {
  activeCategory: number
  handleCategoryClick: (id: number) => void
}

export const useCategories = (props: CategoriesProps) => {
  const categories: ICategory[] | null = useSelector(
    (state: RootState) => state.categories.categories
  )

  return {
    ...props,
    categories,
  }
}
