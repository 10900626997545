import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import IRestaurantInfo from 'models/IRestaurantInfo'

export interface LayoutProps {
  children: React.ReactNode
}

export const useLayout = (props: LayoutProps) => {
  const isLoadingRestaurantInfo: boolean = useSelector(
    (state: RootState) => state.restaurantInfo.isLoading
  )
  const isLoadingCategories: boolean = useSelector(
    (state: RootState) => state.categories.isLoading
  )
  const isLoadingSubCategories: boolean = useSelector(
    (state: RootState) => state.subCategories.isLoading
  )
  const isLoadingProducts: boolean = useSelector(
    (state: RootState) => state.products.isLoading
  )
  const restaurantInfo: IRestaurantInfo | null = useSelector(
    (state: RootState) => state.restaurantInfo.restaurantInfo
  )

  const isLoading =
    isLoadingRestaurantInfo ||
    isLoadingCategories ||
    isLoadingSubCategories ||
    isLoadingProducts

  const isRestaurantFound = !!restaurantInfo?.id

  const isVisible = !isLoading && isRestaurantFound

  return {
    ...props,
    isVisible,
  }
}
