import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getCategories as getCategoriesReducer,
} from 'reducers/categories.reducer'
import { categoriesService } from 'services/categories.service'
import ICategory from 'models/ICategory'

export const getCategories = (slug: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await categoriesService.getCategories(slug)
    if (response) {
      dispatch(
        getCategoriesReducer({ categories: response.data as ICategory[] })
      )
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}
