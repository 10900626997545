import makeRequest from 'utils/makeRequest'
import { PUBLIC_ENDPOINTS } from 'config/apiEndpoints'
import IResponseApi from 'models/IResponseApi'
import ISendEmail from 'models/ISendEmail'

export const comingSoonService = {
  sendEmail: async (emailBody: ISendEmail): Promise<IResponseApi> => {
    try {
      const response: IResponseApi = await makeRequest(
        'POST',
        PUBLIC_ENDPOINTS.SendEmail,
        JSON.stringify(emailBody),
        'application/json'
      )

      return response
    } catch (error) {
      throw new Error('Sending Email Failed')
    }
  },
}
