import { PriceList } from 'models/IProduct'

export interface PriceListProps {
  currency: string
  priceList: PriceList[]
}

export const usePriceList = (props: PriceListProps) => {
  return {
    ...props,
  }
}
