import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { RootState } from 'reducers/rootReducer'
import { sendEmail } from 'actions/comingSoon.action'
import ISendEmail from 'models/ISendEmail'

export interface ComingSoonProps {}

export const useComingSoon = (props: ComingSoonProps) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const dispatch = useAppDispatch()

  const isLoading: boolean = useSelector(
    (state: RootState) => state.comingSoon.isLoading
  )
  const isSendEmailSuccess: boolean = useSelector(
    (state: RootState) => state.comingSoon.isSendEmailSuccess
  )

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    const emailBody: ISendEmail = {
      email,
      message,
    }

    dispatch(sendEmail(emailBody))
  }

  useEffect(() => {
    if (isSendEmailSuccess) {
      setEmail('')
      setMessage('')
    }
  }, [isSendEmailSuccess])

  return {
    ...props,
    email,
    message,
    isLoading,
    isSendEmailSuccess,
    setEmail,
    setMessage,
    onSubmit,
  }
}
