import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getRestaurantInfo as getRestaurantInfoReducer,
} from 'reducers/restaurantInfo.reducer'
import { restaurantInfoService } from 'services/restaurantInfo.service'
import IRestaurantInfo from 'models/IRestaurantInfo'

export const getRestaurantInfo =
  (slug: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await restaurantInfoService.getRestaurantInfo(slug)
      if (response) {
        dispatch(
          getRestaurantInfoReducer({
            restaurantInfo: response.data as IRestaurantInfo,
          })
        )
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }
