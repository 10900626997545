import React from 'react'
import { useHome, HomeProps } from './useHome'
import Info from './Info/Info'
import Categories from './Categories/Categories'
import SubCategories from './SubCategories/SubCategories'
import Loader from 'screens/Loader/Loader'
import NotFound from 'screens/NotFound/NotFound'

const Home: React.FC<HomeProps> = (props: HomeProps) => {
  const { isLoading, isRestaurantFound, activeCategory, handleCategoryClick } =
    useHome(props)

  return isLoading ? (
    <Loader />
  ) : isRestaurantFound ? (
    <main>
      <Info />
      <Categories
        activeCategory={activeCategory}
        handleCategoryClick={handleCategoryClick}
      />
      <SubCategories activeCategory={activeCategory} />
    </main>
  ) : (
    <NotFound />
  )
}

export default Home
