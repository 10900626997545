import { createSlice } from '@reduxjs/toolkit'

export interface ComingSoonState {
  isLoading: boolean
  isSendEmailSuccess: boolean
}

const initialState: ComingSoonState = {
  isLoading: false,
  isSendEmailSuccess: false,
}

const comingSoonSlice = createSlice({
  name: 'comingSoon',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    sendEmail(state) {
      state.isLoading = false
      state.isSendEmailSuccess = true
    },
  },
})

export const { apiRequest, apiFailure, sendEmail } = comingSoonSlice.actions
export default comingSoonSlice.reducer
