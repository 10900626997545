import makeRequest from 'utils/makeRequest'
import { PUBLIC_ENDPOINTS } from 'config/apiEndpoints'
import IResponseApi from 'models/IResponseApi'

export const subCategoriesService = {
  getSubCategories: async (slug: string): Promise<IResponseApi> => {
    try {
      const url = `${PUBLIC_ENDPOINTS.GetSubCategories}?slug=${slug}`
      const response: IResponseApi = await makeRequest('GET', url)

      return response
    } catch (error) {
      throw new Error('Get sub categories failed')
    }
  },
}
