import React, { Fragment } from 'react'
import { usePriceList, PriceListProps } from './usePriceList'
import styles from './PriceList.module.scss'

const PriceList: React.FC<PriceListProps> = (props: PriceListProps) => {
  const { priceList, currency } = usePriceList(props)

  return priceList && priceList.length ? (
    <div className='dropdown'>
      <button
        className={`dropdown-toggle ${styles.price_list_toggle}`}
        type='button'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
        Prices
      </button>
      <ul className='dropdown-menu dropdown-menu-end'>
        {priceList.map((price, index) => (
          <li key={`price-${price.name}-${index}`}>
            <div className={styles.price_item}>
              <span>{price.name}</span>{' '}
              <span>
                {currency}
                {price.price}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <Fragment />
  )
}

export default PriceList
