import React from 'react'
import { useProduct, ProductProps } from './useProduct'
import PriceList from './PriceList/PriceList'
import styles from './Product.module.scss'

const Product: React.FC<ProductProps> = (props: ProductProps) => {
  const {
    name,
    currency,
    price,
    priceList,
    image,
    description,
    showTooltip,
    handleMouseEnter,
    handleMouseLeave,
  } = useProduct(props)

  return (
    <div className={styles.product}>
      <img
        src={image}
        alt='product background'
        className={styles.image}
        loading='lazy'
      />
      <div className='d-flex justify-content-between'>
        <div className={styles.product_name}>
          {name}
          <span
            className={`${styles.tooltip_container} ${
              description ? '' : 'd-none'
            }`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <i className='bi bi-info-circle'></i>
            {showTooltip && <div className={styles.tooltip}>{description}</div>}
          </span>
        </div>
        {price ? (
          <div className={styles.product_price}>
            {currency}
            {price}
          </div>
        ) : (
          priceList &&
          priceList.length && (
            <PriceList currency={currency} priceList={priceList} />
          )
        )}
      </div>
    </div>
  )
}

export default Product
