import React from 'react'
import { useInfo, InfoProps } from './useInfo'
import styles from './Info.module.scss'

const Info: React.FC<InfoProps> = (props: InfoProps) => {
  const {
    name,
    location,
    locationUrl,
    phone,
    phone2,
    email,
    wifiCode,
    description,
  } = useInfo(props)

  return (
    <div className={styles.info_container}>
      <h1>{name}</h1>
      <div className={styles.parameters}>
        {location && (
          <div className={styles.parameter}>
            <a href={locationUrl} rel='noreferrer' target='_blank'>
              <i className='bi bi-geo-alt me-1'></i>
              {location}
            </a>
          </div>
        )}
        {phone && (
          <div className={styles.parameter}>
            <a href={`tel:${phone}`}>
              <i className='bi bi-telephone me-1'></i>
              {phone}
            </a>

            {phone2 && <a href={`tel:${phone2}`}> / {phone2}</a>}
          </div>
        )}
        {email && (
          <div className={styles.parameter}>
            <a href={`mailto:${email}`}>
              <i className='bi bi-envelope me-1'></i>
              {email}
            </a>
          </div>
        )}
        {wifiCode && (
          <div className={styles.parameter}>
            <i className='bi bi-wifi me-1'></i>
            {wifiCode}
          </div>
        )}
      </div>
      {description && (
        <p className={styles.description}>
          <i className='bi bi-info-circle me-1'></i>
          {description}
        </p>
      )}
    </div>
  )
}

export default Info
