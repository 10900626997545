import React from 'react'
import { useLoader, LoaderProps } from './useLoader'
import styles from './Loader.module.scss'

const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const {} = useLoader(props)

  return (
    <div>
      <div className={styles.loader}>
        <div className={styles.panWrapper}>
          <div className={styles.pan}>
            <div className={styles.food}></div>
            <div className={styles.panBase}></div>
            <div className={styles.panHandle}></div>
          </div>
          <div className={styles.panShadow}></div>
        </div>
      </div>
    </div>
  )
}

export default Loader
