import { createSlice } from '@reduxjs/toolkit'
import IRestaurantInfo from 'models/IRestaurantInfo'

export interface RestaurantInfoState {
  isLoading: boolean
  isGetRestaurantInfoSuccess: boolean
  restaurantInfo: IRestaurantInfo | null
}

const initialState: RestaurantInfoState = {
  isLoading: false,
  isGetRestaurantInfoSuccess: false,
  restaurantInfo: null,
}

const restaurantInfoSlice = createSlice({
  name: 'restaurantInfo',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getRestaurantInfo(state, action) {
      state.isLoading = false
      state.isGetRestaurantInfoSuccess = true
      state.restaurantInfo = action.payload.restaurantInfo
    },
  },
})

export const { apiRequest, apiFailure, getRestaurantInfo } =
  restaurantInfoSlice.actions
export default restaurantInfoSlice.reducer
