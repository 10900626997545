import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import IRestaurantInfo from 'models/IRestaurantInfo'

export interface HeaderProps {
  isVisible: boolean
}

export const useHeader = (props: HeaderProps) => {
  const [profileImage, setProfileImage] = useState<string | null>(null)
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null)

  const restaurantInfo: IRestaurantInfo | null = useSelector(
    (state: RootState) => state.restaurantInfo.restaurantInfo
  )

  useEffect(() => {
    if (restaurantInfo) {
      setProfileImage(restaurantInfo.profileImage)
      setBackgroundImage(restaurantInfo.backgroundImage)
    }
  }, [restaurantInfo])

  return {
    ...props,
    profileImage,
    backgroundImage,
  }
}
