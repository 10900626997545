import React, { Fragment } from 'react'
import { useLayout, LayoutProps } from './useLayout'
import Header from './Header/Header'
import Footer from './Footer/Footer'

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const { children, isVisible } = useLayout(props)

  return (
    <Fragment>
      <Header isVisible={isVisible} />
      {children}
      <Footer isVisible={isVisible} />
    </Fragment>
  )
}

export default Layout
