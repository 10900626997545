import React, { Fragment } from 'react'
import { useHeader, HeaderProps } from './useHeader'
import bannerImg from 'img/banner.jpg'
import logoImg from 'img/logo.webp'
import styles from './Header.module.scss'

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { profileImage, backgroundImage, isVisible } = useHeader(props)
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL

  return isVisible ? (
    <header
      className={styles.header}
      style={{
        backgroundImage: `url(${
          backgroundImage ? `${MEDIA_URL}${backgroundImage}` : bannerImg
        })`,
      }}
    >
      <img
        src={profileImage ? `${MEDIA_URL}${profileImage}` : logoImg}
        className={styles.logo}
        alt='logo'
        width={60}
        height={60}
      />
    </header>
  ) : (
    <Fragment />
  )
}

export default Header
