import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getSubCategories as getSubCategoriesReducer,
} from 'reducers/subCategories.reducer'
import { subCategoriesService } from 'services/subCategories.service'
import ISubCategory from 'models/ISubCategory'

export const getSubCategories =
  (slug: string) => async (dispatch: Dispatch) => {
    try {
      dispatch(apiRequest())

      const response = await subCategoriesService.getSubCategories(slug)
      if (response) {
        dispatch(
          getSubCategoriesReducer({
            subCategories: response.data as ISubCategory[],
          })
        )
      }
    } catch (error) {
      dispatch(apiFailure())
    }
  }
