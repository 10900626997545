export interface SubCategoryProps {
  name: string
  image: string
  link: string
}

export const useSubCategory = (props: SubCategoryProps) => {
  return {
    ...props,
  }
}
