import { useState } from 'react'
import { PriceList } from 'models/IProduct'

export interface ProductProps {
  id: number
  name: string
  image: string
  currency: string
  price: string | null
  priceList: PriceList[] | null
  description: string
}

export const useProduct = (props: ProductProps) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }
  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return {
    ...props,
    showTooltip,
    handleMouseEnter,
    handleMouseLeave,
  }
}
