import { Dispatch } from 'redux'
import {
  apiRequest,
  apiFailure,
  getProducts as getProductsReducer,
} from 'reducers/products.reducer'
import { productsService } from 'services/products.service'
import IProduct from 'models/IProduct'

export const getProducts = (slug: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(apiRequest())

    const response = await productsService.getProducts(slug)
    if (response) {
      dispatch(getProductsReducer({ products: response.data as IProduct[] }))
    }
  } catch (error) {
    dispatch(apiFailure())
  }
}
