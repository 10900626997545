import React, { Fragment } from 'react'
import { useFooter, FooterProps } from './useFooter'
import { Link } from 'react-router-dom'
import styles from './Footer.module.scss'

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  const { isVisible } = useFooter(props)

  return isVisible ? (
    <footer className={styles.footer}>
      <Link to='/' className={styles.link} target='_blank'>
        bytebuffetbox.com
      </Link>
    </footer>
  ) : (
    <Fragment />
  )
}

export default Footer
