import apiConfig from 'config/apiConfig'

const makeRequest = async (
  method: string,
  url: string,
  data?: any,
  contentType?: string
) => {
  try {
    const headers: Record<string, string> = {}

    if (contentType) headers['Content-Type'] = contentType

    const response = await apiConfig.request({
      method,
      url,
      data,
      headers,
    })

    return response.data
  } catch (error) {
    console.error('Something went wrong, please try again later!')
  }
}

export default makeRequest
