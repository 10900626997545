import React from 'react'
import { useSubCategory, SubCategoryProps } from './useSubCategory'
import { Link } from 'react-router-dom'
import styles from './SubCategory.module.scss'

const SubCategory: React.FC<SubCategoryProps> = (props: SubCategoryProps) => {
  const { name, image, link } = useSubCategory(props)

  return (
    <Link to={link} className='text-decoration-none'>
      <div className={styles.sub_category}>
        <img
          src={image}
          alt='sub category background'
          className={styles.image}
          loading='lazy'
        />
        <span className={styles.sub_category_name}>{name}</span>
      </div>
    </Link>
  )
}

export default SubCategory
