import React from 'react'
import { useComingSoon, ComingSoonProps } from './useComingSoon'
import styles from './ComingSoon.module.scss'

const ComingSoon: React.FC<ComingSoonProps> = (props: ComingSoonProps) => {
  const {
    email,
    message,
    isLoading,
    isSendEmailSuccess,
    setEmail,
    setMessage,
    onSubmit,
  } = useComingSoon(props)

  return (
    <main className={styles.coming_soon_container}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1 className={styles.title}>ByteBuffetBox</h1>
            <h2 className={styles.slogan}>
              Your perfect choice for your restaurant online menu.
            </h2>
            <p>
              If you would like to join the ranks of early birds and secure a
              substantial discount, kindly provide us with your email or phone
              number, and we will be sure to reach out to you.
            </p>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className='row'>
            <div className='col-12 mb-2'>
              <input
                type='text'
                id='email'
                name='email'
                value={email}
                autoComplete='on'
                onChange={(e) => setEmail(e.target.value)}
                className={`form-control ${styles.glass}`}
                placeholder='Email or Phone Number'
                required
              />
            </div>
            <div className='col-12 mb-2'>
              <textarea
                id='message'
                name='message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className={`form-control ${styles.glass}`}
                rows={5}
                placeholder='Message (optional)'
              ></textarea>
            </div>
            <div className='col-12'>
              <button
                type='submit'
                disabled={isLoading}
                className={`btn btn-dark ${styles.btn}`}
              >
                {isLoading ? 'Please wait...' : 'Submit'}
              </button>
            </div>
            <div className={`col-12 ${isSendEmailSuccess ? '' : 'd-none'}`}>
              Thank you for your trust, we will contact you as soon as possible.
            </div>
          </div>
        </form>
      </div>
    </main>
  )
}

export default ComingSoon
