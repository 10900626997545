import makeRequest from 'utils/makeRequest'
import { PUBLIC_ENDPOINTS } from 'config/apiEndpoints'
import IResponseApi from 'models/IResponseApi'

export const productsService = {
  getProducts: async (slug: string): Promise<IResponseApi> => {
    try {
      const url = `${PUBLIC_ENDPOINTS.GetProducts}?slug=${slug}`
      const response: IResponseApi = await makeRequest('GET', url)

      return response
    } catch (error) {
      throw new Error('Get products failed')
    }
  },
}
