import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { getRestaurantInfo } from 'actions/restaurantInfo.action'
import { getCategories } from 'actions/categories.action'
import { getSubCategories } from 'actions/subCategories.action'
import { getProducts } from 'actions/products.action'
import IRestaurantInfo from 'models/IRestaurantInfo'
import ICategory from 'models/ICategory'
import ISubCategory from 'models/ISubCategory'
import IProduct from 'models/IProduct'
import { RootState } from 'reducers/rootReducer'

export interface HomeProps {}

export const useHome = (props: HomeProps) => {
  const { restaurantName } = useParams<{ restaurantName: string }>()
  const [activeCategory, setActiveCategory] = useState<number>(1)

  const handleCategoryClick = (id: number) => {
    setActiveCategory(id)
  }

  const dispatch = useAppDispatch()

  const restaurantInfo: IRestaurantInfo | null = useSelector(
    (state: RootState) => state.restaurantInfo.restaurantInfo
  )
  const categories: ICategory[] | null = useSelector(
    (state: RootState) => state.categories.categories
  )
  const subCategories: ISubCategory[] | null = useSelector(
    (state: RootState) => state.subCategories.subCategories
  )
  const products: IProduct[] | null = useSelector(
    (state: RootState) => state.products.products
  )
  const isLoadingRestaurantInfo: boolean = useSelector(
    (state: RootState) => state.restaurantInfo.isLoading
  )
  const isLoadingCategories: boolean = useSelector(
    (state: RootState) => state.categories.isLoading
  )
  const isLoadingSubCategories: boolean = useSelector(
    (state: RootState) => state.subCategories.isLoading
  )
  const isLoadingProducts: boolean = useSelector(
    (state: RootState) => state.products.isLoading
  )
  const isLoading =
    isLoadingRestaurantInfo ||
    isLoadingCategories ||
    isLoadingSubCategories ||
    isLoadingProducts

  useEffect(() => {
    try {
      if (!restaurantInfo) dispatch(getRestaurantInfo(restaurantName || ''))
      if (!categories || !categories.length)
        dispatch(getCategories(restaurantName || ''))
      if (!subCategories || !subCategories.length)
        dispatch(getSubCategories(restaurantName || ''))
      if (!products || !products.length)
        dispatch(getProducts(restaurantName || ''))
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (categories && categories.length) setActiveCategory(categories[0].id)
  }, [categories])

  useEffect(() => {
    if (restaurantInfo) {
      const oldStyleAttributes = document.getElementsByClassName(
        'restaurant-variables'
      )

      if (!oldStyleAttributes.length) {
        const styleTag = document.createElement('style')

        styleTag.setAttribute('class', 'restaurant-variables')
        styleTag.innerHTML = `
          :root {
            --primary-color: ${restaurantInfo.primaryColor};
            --secondary-color: ${restaurantInfo.secondaryColor};
            --tertiary-color: ${restaurantInfo.tertiaryColor};
          }
        `
        document.head.appendChild(styleTag)
      }
    }
  }, [restaurantInfo])

  return {
    ...props,
    isRestaurantFound: !!restaurantInfo?.id,
    isLoading,
    activeCategory,
    handleCategoryClick,
  }
}
