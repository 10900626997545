import { useSelector } from 'react-redux'
import { RootState } from 'reducers/rootReducer'
import IRestaurantInfo from 'models/IRestaurantInfo'

export interface InfoProps {}

export const useInfo = (props: InfoProps) => {
  const restaurantInfo: IRestaurantInfo | null = useSelector(
    (state: RootState) => state.restaurantInfo.restaurantInfo
  )

  return {
    ...props,
    ...restaurantInfo,
  }
}
