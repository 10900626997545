import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useRouter, RouterProps } from './useRouter'
import Layout from 'components/Layout/Layout'
import Home from 'screens/Home/Home'
import Category from 'screens/Category/Category'
import ComingSoon from 'screens/ComingSoon/ComingSoon'

const Router: React.FC<RouterProps> = (props: RouterProps) => {
  const {} = useRouter(props)

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path='/' element={<ComingSoon />} />
          <Route path='/:restaurantName/*' element={<Home />} />
          <Route
            path=':restaurantName/Category/:subCategoryId'
            element={<Category />}
          />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

export default Router
