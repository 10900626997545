import { createSlice } from '@reduxjs/toolkit'
import ISubCategory from 'models/ISubCategory'

export interface SubCategoriesState {
  isLoading: boolean
  isGetSubCategoriesSuccess: boolean
  subCategories: ISubCategory[] | null
}

const initialState: SubCategoriesState = {
  isLoading: false,
  isGetSubCategoriesSuccess: false,
  subCategories: null,
}

const subCategoriesSlice = createSlice({
  name: 'subCategories',
  initialState,
  reducers: {
    apiRequest: (state) => {
      state.isLoading = true
    },
    apiFailure: (state) => {
      state.isLoading = false
    },
    getSubCategories(state, action) {
      state.isLoading = false
      state.isGetSubCategoriesSuccess = true
      state.subCategories = action.payload.subCategories
    },
  },
})

export const { apiRequest, apiFailure, getSubCategories } =
  subCategoriesSlice.actions
export default subCategoriesSlice.reducer
